import {Container, Col, Row} from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from "../assets/img/meter1.svg"
import meter2 from "../assets/img/meter2.svg"
import meter3 from "../assets/img/meter3.svg"
import colorSharp from "../assets/img/color-sharp.png"
import techs from "../assets/img/techs.png"
import Spotify from 'react-spotify-embed'

export const Playlist = () =>{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
      return(
        <section className="skill" id="playlist">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx">
                            <h2>
                                Playlists
                            </h2>
                            <p>
                                Some mindful instruments and lofi coding tracks i've collected from spotify
                            </p>
                            <Carousel responsive={responsive} infinite={true} className="skill-slide">
                                <div className="item">

                                    <Spotify link="https://open.spotify.com/playlist/5NFb6Q6HWdul4zwD0Z8dXB?si=6f76f982cb9c4ad0"/>

                                </div>
                                <div className="item">

                                    <Spotify link="https://open.spotify.com/playlist/4Pg7gzql2lT6RiJyQ93ue5?si=18834110f14b408b"/>

                                </div>
                                <div className="item">

                                    <Spotify link="https://open.spotify.com/playlist/5939ZlJFruqoHnDKcdaCKY?si=444f8d41d3c1487e"/>

                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className='background-image-left' src={colorSharp} />
        </section>
      )
}