import { Navbar, Container, Nav } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import logo from '../assets/img/logo.jpg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, seScrolled] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        seScrolled(true);
      } else {
        seScrolled(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });
  const onUpdateActiveLink=(value)=>{
    setActiveLink(value);
  }
  return (
    <Navbar expand="lg" color='dark' className={scrolled ? 'scrolled' : ''}>
      <Container>
        <Navbar.Brand href="#home">
          <img className="logo" src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" className={activeLink==='home' ? 'active navbar-link': 'navbar-link'} onClick={()=>onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href="#skills" className={activeLink==='skills' ? 'active navbar-link': 'navbar-link'} onClick={()=>onUpdateActiveLink('skills')}>Skills</Nav.Link>
            <Nav.Link href="#projects" className={activeLink==='projects' ? 'active navbar-link': 'navbar-link'} onClick={()=>onUpdateActiveLink('projects')}>Projects</Nav.Link>
            <Nav.Link href="#git" className={activeLink==='git' ? 'active navbar-link': 'navbar-link'} onClick={()=>onUpdateActiveLink('git')}>Git</Nav.Link>
            <Nav.Link href="#playlist" className={activeLink==='playlist' ? 'active navbar-link': 'navbar-link'} onClick={()=>onUpdateActiveLink('blogs')}>playlist</Nav.Link>
            <Nav.Link href="#snippet" className={activeLink==='snippet' ? 'active navbar-link': 'navbar-link'} onClick={()=>onUpdateActiveLink('blogs')}>Snippet Dump</Nav.Link>
          </Nav>
          <span className="navbar-text">

            <div className="social-icon">
              <a href="https://www.linkedin.com/in/happy-christian-71a6a8160/" target="_this">
                <img src={navIcon1} alt="" />
              </a>
              <a href="https://www.facebook.com/yppah.christian.77/" target="_this">
                <img src={navIcon2} alt="" />
              </a>
              <a href="https://www.instagram.com/happy._.christian.7/" target="_this">
                <img src={navIcon3} alt="" />
              </a>
            </div>
            <button className="vvd" onClick={() => window.location='mailto:happy.christian.hc3@gmail.com'}>
              <span>Email Me</span>
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
