import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.jpg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
          <div clssName="social-icon">
          <button onClick={() => window.location='https://www.buymeacoffee.com/happychrisT/'} >
            <img align="left" src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" height="50" width="210" alt="happychrisT" />
           </button>
            {/* <img src={logo} alt="Logo" /> */}
            </div>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">

            <div className="social-icon">
            <a href="https://www.linkedin.com/in/happy-christian-71a6a8160/" target="_this">
                <img src={navIcon1} alt="" />
              </a>
              <a href="https://www.facebook.com/yppah.christian.77/" target="_this">
                <img src={navIcon2} alt="" />
              </a>
              <a href="https://www.instagram.com/happy._.christian.7/" target="_this">
                <img src={navIcon3} alt="" />
              </a>

            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
