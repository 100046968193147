import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import headerImg from '../assets/img/header-img.svg';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { motion } from 'framer-motion';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [
    'Front End',
    'Back End',
    'DevOps',
    'Databases',
    'Gaming',
    'Journaling',
  ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  };
  const MyComponent = ({ isVisible }) => (
    <motion.div animate={{ opacity: isVisible ? 1 : 0 }} />
  );

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? '' : ''
                  }
                >
                  <span className="tagline">Welcome to my Portfolio</span>
                  <h1>
                    {`Hi! I'm Happy Christian`}{' '}
                    <span
                      className="txt-rotate"
                      dataPeriod="1000"
                      data-rotate='[ "Web Developer", "Web Designer", "UI/UX Designer" ]'
                    >
                      <span className="wrap" style={{height:'100px'}}>{text}</span>
                    </span>
                  </h1>

                  <p className="glow">
                  Hello! I'm Happy Christian, a dedicated and creative Full Stack Developer with four years of experience in crafting web applications and digital solutions. With a strong foundation in both front-end and back-end development, I have a passion for creating innovative and user-friendly products that stand out in the digital space.

          Over the years, I've honed my skills in a wide range of technologies My ability to adapt and learn new technologies quickly allows me to stay ahead of the curve and deliver cutting-edge solutions for clients across various industries.

          My journey as a developer began with a genuine curiosity about technology and a desire to make a meaningful impact on the digital landscape. I thrive on overcoming complex challenges and strive to continuously improve my skills in order to provide the best possible solutions for my clients.

          Collaboration is key to my approach, as I believe that teamwork and open communication lead to the most effective and innovative outcomes. I enjoy working with cross-functional teams, and my strong problem-solving and analytical skills make me an asset in any project.


                  </p>
                    <span role="img" aria-label="sheep">
                      🐑
                    </span>

                  <button onClick={() => console.log('connect')}>
                    Let’s Connect <ArrowRightCircle size={25} />
                  </button>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div

                >
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
            <motion.h1
                    animate={{ x: [50, 150, 50], opacity: 1, scale: 1 }}
                    transition={{
                      duration: 5,
                      delay: 0.3,
                      ease: [0.5, 0.71, 1, 1.5],
                    }}
                    initial={{ opacity: 0, scale: 0.5 }}
                    whileHover={{ scale: 1.2 }}
                  ></motion.h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
