import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import sambhav from '../assets/img/sambhav_proj.png';
import naati from '../assets/img/nati_proj.png';
import promed from '../assets/img/promed_proj.png';
import onelineeng_proj from '../assets/img/onelineeng_proj.png';
import colorSharp2 from '../assets/img/color-sharp2.png';
import coming_soon from '../assets/img/coming_soon.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
export const Git = () => {
  return (
    <section className="project" id="git">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? '' : ''}>
                  <h2>Git Stats</h2>
                  <p>
                    My Github profile and some stats{' '}
                    <a
                      href="https://github.com/happychristian777"
                      target="_this"
                    >
                      Github Profile
                    </a>
                    .
                  </p>
                  <Row>
                    <Col size={4}>
                    <img src="https://ghchart.rshah.org/happychristian777" alt="2016rshah's Github chart" />
                    </Col>
                    <Col size={4}>
                      <img src=" 	https://github-readme-stats.vercel.app/api?username=happychristian777&theme=blue-green" />
                    </Col>
                    <Col size={4}>
                      <img src=" 	https://starchart.cc/happychristian777/portfolio.svg" />
                    </Col>
                  </Row>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
