import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import sambhav from "../assets/img/sambhav_proj.png";
import naati from "../assets/img/nati_proj.png";
import promed from "../assets/img/promed_proj.png"
import onelineeng_proj from "../assets/img/onelineeng_proj.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import coming_soon from "../assets/img/coming_soon.png"
import twitter from "../assets/img/twitter_clone_proj.jpg"
import ashwin from "../assets/img/ashwin_proj.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';


export const Projects = () => {

  const projects = [
    {
      title:"Twitter Clone",
      description:"Stack: Prisma, Nutx3, Tailwind, MongoDb",
      imgUrl:twitter
    },
    {
      title: "Sambhav Initiatives NGO",
      description: "NGO Website with podcasts and blogging services and admin portal",
      imgUrl: sambhav,
    },
    {
      title: "Naati Mocktest Platform",
      description: "A Moack Test module for naati exams with material facilities and admin portal",
      imgUrl: naati,
    },
    {
      title: "Promed Pharma ",
      description: "Pharmaceutical informative webiste with e-commerce like products module and admin portal",
      imgUrl: promed,
    },
    {
      title: "Import Export Portal",
      description: "Fiji's turmeric company handles import amd export globally",
      imgUrl: ashwin,
    },

    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: onelineeng_proj,
    },

  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "": ""}>
                <h2>Projects</h2>
                <p>Here are few past design projects I've worked on. Want to see more? <a href="mailto:happy.christian.hc3@gmail.com">Email me</a>.</p>

                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>

              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
