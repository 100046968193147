import {Container, Col, Row} from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from "../assets/img/meter1.svg"
import meter2 from "../assets/img/meter2.svg"
import meter3 from "../assets/img/meter3.svg"
import colorSharp from "../assets/img/color-sharp.png"
import techs from "../assets/img/techs.png"
import Spotify from 'react-spotify-embed'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

/*skills*/
export const Skills = () =>{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
      return(

        <section className="skill" id="skills">

        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>I've worked on several technologies frontend, backend and DevOps for web and APIs development</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <h5>FrontEnd</h5>

                                <img src={'https://img.shields.io/badge/Vue.js-35495E?style=for-the-badge&logo=vue.js&logoColor=4FC08D'}/>
                                <img src={'https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB'}/>
                                <img src={'https://img.shields.io/badge/JavaScript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=black'}/>
                                <img src={'https://img.shields.io/badge/Bootstrap-563D7C?style=for-the-badge&logo=bootstrap&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/Tailwind_CSS-38B2AC?style=for-the-badge&logo=tailwind-css&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/CSS-239120?&style=for-the-badge&logo=css3&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/HTML-239120?style=for-the-badge&logo=html5&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/Tailwind_CSS-38B2AC?style=for-the-badge&logo=tailwind-css&logoColor=white'}/>
                            </div>
                            <div className="item">

                                <h5>BackEnd</h5>
                                <img src={'https://img.shields.io/badge/Python-3776AB?style=for-the-badge&logo=python&logoColor=white'}/>
                               <img src={'https://img.shields.io/badge/PHP-777BB4?style=for-the-badge&logo=php&logoColor=white'}/>
                               <img src={'https://img.shields.io/badge/Node.js-43853D?style=for-the-badge&logo=node.js&logoColor=white'}/>
                               <img src={'https://img.shields.io/badge/Laravel-FF2D20?style=for-the-badge&logo=laravel&logoColor=white'}/>
                               <img src={'https://img.shields.io/badge/.NET-5C2D91?style=for-the-badge&logo=.net&logoColor=white'}/>
                               <img src={'https://img.shields.io/badge/C%23-239120?style=for-the-badge&logo=c-sharp&logoColor=white'}/>
                               <img src={'https://img.shields.io/badge/Java-ED8B00?style=for-the-badge&logo=java&logoColor=white'}/>

                            </div>
                            <div className="item">
                                <h5>Other</h5>
                                <img src={'https://img.shields.io/badge/Amazon_AWS-232F3E?style=for-the-badge&logo=amazon-aws&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/Vercel-000000?style=for-the-badge&logo=vercel&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/Jenkins-D24939?style=for-the-badge&logo=Jenkins&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/Adobe%20Dreamweaver-072401?style=for-the-badge&logo=Adobe%20Dreamweaver&logoColor=34F400'}/>
                                <img src={'https://img.shields.io/badge/Atom-66595C?style=for-the-badge&logo=Atom&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/Eclipse-2C2255?style=for-the-badge&logo=eclipse&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/Visual_Studio_Code-0078D4?style=for-the-badge&logo=visual%20studio%20code&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/sublime_text-%23575757.svg?&style=for-the-badge&logo=sublime-text&logoColor=important'}/>
                                <img src={'https://img.shields.io/badge/GIT-E44C30?style=for-the-badge&logo=git&logoColor=white'}/>
                                <img src={'https://img.shields.io/badge/R-276DC3?style=for-the-badge&logo=r&logoColor=white'}/>
                           </div>
                           <div className="item">
                                <h5>Learning</h5>
                                <img src={'https://img.shields.io/badge/Prisma-3982CE?style=for-the-badge&logo=Prisma&logoColor=white'}/>
                           </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
      )
}